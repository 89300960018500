// Start Main Colors Using Sass variables [ COnvert Css variables To Sass variables For Easy Syntax ]

$main-color: var(--main-color);
$second-color: var(--second-color);
$third-color: var(--third-color);
$fourth-color: var(--fourth-color);
$white-color: var(--white-color);
$logo-text-color: var(--logo-text-color);

$background-color: var(--background-color);
$upper-background-color: var(--upper-background-color);

$box-shadow-third-color: var(--box-shadow-third-color);
$box-shadow-main-color: var(--box-shadow-main-color);
$box-shadow-white-color: var(--box-shadow-white-color);

// End Main Colors Using Sass variables [ COnvert Css variables To Sass variables For Easy Syntax ]
