@import "./../../../Sass/mixins.scss";

.team-header {
  margin-bottom: 50px;

  .team-title {
    @include section-title;
  }

  .team-paragraph {
    @include section-paragraph;
  }
}
