@import "./../../../Sass/variables.scss";
@import "./../../../Sass/mixins.scss";

.footer-links {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 50px;
  margin-bottom: 120px;

  @media (max-width: $maxMediumScreens) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media (max-width: $maxSmallScreens) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: $maxXSmallScreens) {
    grid-template-columns: repeat(1, 1fr);
    margin-bottom: 70px;
  }

  .links {
    .links-title {
      color: #fff;
      font-size: 1.7rem;
      margin-bottom: 20px;
      cursor: default;
    }

    .links-list {
      .links-item {
        line-height: 2.2;

        .links-link {
          @include reset-link;
          color: #fff;
          transition: all 0.2s linear;
          display: inline-block;

          &:hover {
            font-weight: bold;
            transform: translateX(5px);
          }
        }
      }
    }
  }
}
