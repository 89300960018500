.page {
  min-height: calc(100vh - (20vh + 10rem));
  margin-top: 8%;
}

.section-center {
  width: 90vw;
  margin: 0 auto;
  max-width: var(--max-width);
}

.sports {
  display: grid;
  gap: 3rem 1.5rem;
  margin: 4rem auto;
}

@media (min-width: 768px) {
  .sports {
    grid-template-columns: 200px 1fr;
  }
}
