@import "../../../Sass/variables";
@import "../../../Sass/mixins";

.services-container {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;

  @media (max-width: $maxMediumScreens) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: $maxSmallScreens) {
    grid-template-columns: 1fr;
  }

  .services-card {
    position: relative;
    background-color: $upper-background-color;
    padding: 30px;
    border-radius: 30px;
    box-shadow: 4px 4px 12px #00000021;
    transition: background-color 0.3s linear;
    overflow: hidden;

    @media (max-width: $maxSmallScreens) {
      width: 400px;
      margin: auto;
    }

    @media (max-width: $maxXSmallScreens) {
      width: 100%;
    }

    .services-link {
      @include overlay-link;
    }

    .card-title {
      @include second-header;
      font-size: 1.3rem;
      transition: color 0.3s linear;

      @media (min-width: $minLargeScreens) and (max-width: $maxLargeScreens) {
        font-size: 1.17rem;
      }
    }

    .card-img {
      width: 100%;
      height: 250px;
      margin-top: 20px;
      margin-bottom: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      user-select: none;

      @media (min-width: $minXXLargeScreens) {
        height: 290px;
      }

      @media (max-width: $maxMediumScreens) {
        height: 350px;
      }

      @media (max-width: $maxSmallScreens) {
        height: auto;
      }

      img {
        width: 90%;
        margin: auto;
        display: block;

        @media (max-width: $maxMediumScreens) {
          width: 70%;
        }
      }
    }

    &:nth-of-type(2),
    &:last-of-type {
      .card-img {
        position: relative;
        left: 10px;

        img {
          width: 120%;

          @media (max-width: $maxSmallScreens) {
            width: 100%;
          }
        }
      }
    }

    .card-paragraph {
      @include body-text;
      font-size: 1.1rem;
      transition: color 0.3s linear;

      @media (min-width: $minLargeScreens) and (max-width: $maxLargeScreens) {
        font-size: 1rem;
      }
    }

    &:hover {
      background-color: $second-color;

      .card-title,
      .card-paragraph {
        color: #fff;
      }
    }
  }
}
