@import "./../../../Sass/variables";
@import "./../../../Sass/mixins";

.send-message {
  position: relative;

  .background-image {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-52%, -50%) scale(0.66);
    user-select: none;
    z-index: 1;

    @media (max-width: $maxMediumScreens) {
      transform: translate(-53%, -50%) scale(0.5);
    }

    @media (max-width: $maxSmallScreens) {
      transform: translate(-50%, -50%) scale(0.62);
    }
  }

  .contact-form {
    position: relative;
    z-index: 2;
    margin: auto;
    width: 97%;

    @media (max-width: $maxMediumScreens) {
      width: 90%;
    }

    @media (max-width: $maxSmallScreens) {
      width: 90%;
    }

    @media (max-width: $maxXSmallScreens) {
      width: 100%;
    }

    label {
      display: block;
      color: $third-color;
      font-size: 1.5rem;
      margin-left: 15px;
      margin-bottom: 10px;
    }

    input,
    textarea {
      @include reset-input;
      background-color: $upper-background-color;
      border-radius: 16px;
      box-shadow: 6px 6px 9px #0000000d;
      display: block;
      font-size: 1.2rem;
      color: $third-color;

      &:not([type="submit"]) {
        width: 100%;
        padding: 15px 20px;
        margin-bottom: 15px;
      }

      &::placeholder {
        color: $third-color;
        opacity: 0.7;
        font-size: 1rem;
        transition: all 0.2s linear;
      }

      &:focus {
        &::placeholder {
          opacity: 0;
        }
      }
    }

    input {
      &[type="submit"] {
        background-color: $second-color;
        color: #fff;
        padding: 10px 30px;
        cursor: pointer;
      }
    }

    textarea {
      height: 200px;
      resize: none;
    }

    & > div {
      display: grid;
      grid-template-columns: auto 1fr;
      align-items: center;
      justify-content: center;
      gap: 10px;
      color: #fff;

      .status {
        .loading {
          display: block;
          width: 40px;
          height: 40px;
          background-color: transparent;
          position: relative;

          &:after {
            content: " ";
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%) rotate(0);
            display: block;
            width: 32px;
            height: 32px;
            border-radius: 50%;
            border-width: 4px;
            border-style: solid;
            border-color: #fff transparent #fff transparent;
            animation: loading 1.2s linear infinite;
          }
        }

        p {
          font-weight: bold;
        }
      }
    }
  }
}

@keyframes loading {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }

  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}
