@import "./../../../Sass/mixins";

.about-header {
  margin-bottom: 80px;

  .about-title {
    @include section-title;
  }

  .about-paragraph {
    @include section-paragraph;
  }
}
