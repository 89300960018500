@import "./../../variables.scss";
@import "./../reset.scss";
@import "./../fonts.scss";

// Logo Mixin
@mixin logo {
  @include reset-link;
  display: flex;
  align-items: center;

  // Uncomment when deploying under Construction page
  position: relative;
  top: 20px;
  left: 20px;
}

// Logo Image Mixin
@mixin logo-image {
  display: block;
  position: relative;
  user-select: none;
  width: 50px;
}

// Logo Text Mixin
@mixin logo-text {
  @include first-header;
  font-size: 1.6rem;
  color: $logo-text-color;
  user-select: none;
  margin-left: 5px;
}
