@import "./../../Sass/variables";

.navbar {
  background-color: transparent;
  padding-top: 30px;
  padding-bottom: 30px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  z-index: 1000;
  transition: all 0.3s linear;

  // Navbar Inner Using For Grid System
  .navbar-inner {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &.scroll {
    background-color: $upper-background-color;
    box-shadow: 0 2px 15px $box-shadow-third-color;
    padding-top: 20px;
    padding-bottom: 20px;
  }

  // Handle Open Navbar In Responsive Mode
  &.open {
    background-color: $upper-background-color;
    box-shadow: none;

    .navbar-responsive-btn {
      border: 1px solid $main-color;
      background-color: transparent;

      .line {
        background-color: $main-color;

        &:first-of-type {
          transform: translate(0, 8px) rotate(45deg);
        }

        &:nth-of-type(2) {
          transform: translateX(5px);
          opacity: 0;
        }

        &:last-of-type {
          transform: translate(0, -8px) rotate(-45deg);
        }
      }
    }

    .navbar-list {
      @media (max-width: $maxMediumScreens) {
        height: 313px;
        padding: 20px;
      }
    }
  }
}
