@import "./../../variables.scss";
@import "./../reset.scss";
@import "./../fonts.scss";

// Overlay Link Mixin [ Used For Make Collect Of Elements As Link ]
@mixin overlay-link {
  @include reset-link;
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  z-index: 3;
}
